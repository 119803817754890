import React, { useState } from 'react'
import firebase from 'firebase/app'

function CreatePopupStore() {
    const auth = firebase.auth();
    const storeCollection = firebase.firestore().collection('stores')
    const [formName, setFormName] = useState('')

    const createStore = async (e) => {
        e.preventDefault();
        const { uid } = auth.currentUser;
        await storeCollection.add({
            name: formName,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: uid
        })

        setFormName('')
    }

    return (
        <div>
            <p>Create a new popup webstore</p>
            <form onSubmit={createStore}>
                <label>
                    Name:
                    <input value={formName} onChange={(e) => setFormName(e.target.value)}/>
                </label>
                <input type="submit" value="Submit" />
            </form>
        </div>
    )
}

export default CreatePopupStore