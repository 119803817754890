
import React from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Container } from '@material-ui/core';
import './App.css';
import Main from './pages/Main'
import Sheets from './pages/Sheets'
import ListSheets from './pages/ListSheets'

import firebase from 'firebase/app'
import "firebase/analytics";
import 'firebase/firestore'
import 'firebase/auth'

import { useAuthState } from 'react-firebase-hooks/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZ4k7hgp16zNxC9bE31zdVSx5S9Dk0424",
  authDomain: "collect-farm.firebaseapp.com",
  databaseURL: "https://collect-farm-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "collect-farm",
  storageBucket: "collect-farm.appspot.com",
  messagingSenderId: "479521437760",
  appId: "1:479521437760:web:2a4a950a9e29c1b69dc7a5",
  measurementId: "G-HDRRCD8PCK"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

function App() {
  useAuthState(auth);
  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          tabulari.se
        </header>
        <Container>
            <>
              <Switch>
                <Route path="/sheets/all" children={<ListSheets />} />
                <Route path="/sheets/:sheetParam?" children={<Sheets />} />
                <Route path="/stores" children={<Main />} />
                <Route path="/" children={<Sheets />} />
              </Switch>
            </> 
        </Container>
      </div>
    </BrowserRouter>
  );
}

export default App;
