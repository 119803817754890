import React, { useEffect } from 'react'
import { SignOut } from '../components'
import firebase from 'firebase/app'
import CreatePopupStore from './CreatePopupStore';
import ListPopupStores from './ListPopupStores';

const Main = () => {
    const updateUser = (userRef, currentUser) => {
        userRef.set({
            name: currentUser.displayName,
        })
        .then(() => {
            console.log("User successfully updated!");
        })
        .catch((error) => {
            console.error("Error writing user: ", error);
        });
    }
   
    const auth = firebase.auth();

    useEffect(() => {
        if (auth.currentUser) {
            const userRef = firebase.firestore().collection('users').doc(auth.currentUser.uid)
            userRef.get().then((user) => {
                if (user.exists) {
                    if(user.data().name !== auth.currentUser.displayName) {
                        updateUser(userRef, auth.currentUser)
                    }
                } else {
                    updateUser(userRef, auth.currentUser)
                }
            }).catch((error) => {
                console.log("Error getting user:", error);
            });

        }
    }, [auth.currentUser])

    return (
        <>
            <section>
                <div>
                    Welcome {`${auth.currentUser.displayName}`}! This is collect.farm!
                </div>
                <div>
                    <CreatePopupStore />
                    <ListPopupStores />
                </div>
            </section>
            <section>
                <SignOut />
            </section>
        </>
    );
};

export default Main