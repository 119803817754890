import React from 'react'
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app'

const ListPopupStores = () => {
    const [users] = useCollection(
        firebase.firestore().collection('users')
    );

    const [stores, loading, error] = useCollection(
        firebase.firestore().collection('stores'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const deleteStore = (id) => {
        firebase.firestore().collection('stores').doc(id).delete()
    }

    const createdAtString = (store) =>
        store.createdAt ? `created ${store.createdAt.toDate().toLocaleString('sv-SE')}` : ''

    const createdBy = (store) => {
        const user = users.docs
            .filter((doc) => doc.id === store.createdBy)
            .map((doc) => doc.data())[0]
        return store.createdBy && user ? `by ${user.name}` : ''
    }
        
    return (
        <div>
            <p>
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <span>Collection: Loading...</span>}
                {stores && (
                    <span>
                        Stores:{' '} <br />
                        {stores.docs.map((doc) => {
                            const store = doc.data();
                            return (
                                <span key={doc.id}>
                                    Name: {store.name} {createdAtString(store)} {createdBy(store)} <button onClick={() => deleteStore(doc.id)}>Delete</button> <br />
                                </span>
                            )
                        })}

                    </span>
                )}
            </p>
        </div>
    );
};

export default ListPopupStores