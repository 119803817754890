import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom"
import {
    Button, CircularProgress, Grid, Paper, 
    TableContainer, Table, TableCell, TableBody, TableRow, TextField
} from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase/app'
import textToCellsParser from '../functions/textToCellsParser'
import styles from './Sheets.module.css';

const TabularisedSheet = ({sheet, loading}) => {
    if (loading) return <CircularProgress />
    return (
        <TableContainer>
            <Table>
                <TableBody>
                     { sheet?.map((row, rowIndex) => {
                        return (
                            <TableRow key={`row-${rowIndex}`}>
                                { row.cells.map((cell, cellIndex) => (
                                    <TableCell
                                        key={`cell-${rowIndex}-${cellIndex}`}
                                        className={styles['cell']}>
                                        {cell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )            
}

const Sheets = () => {
    const auth = firebase.auth()
    const sheetsCollection = firebase.firestore().collection('sheets')
    let { sheetParam } = useParams()
    const [sheetIdFromUrl, setSheetIdFromUrl] = useState(sheetParam)
    const [sheetData, setSheetData] = useState()
    const [pastedData, setPastedData] = useState()
    const [sheetUrl, setSheetUrl] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingDone, setLoadingDone] = useState(false)
    const [creating, setCreating] = useState(false)
    const history = useHistory();

    const handlePaste = (e) => {
        const data = e.clipboardData.getData('text/plain')
        setPastedData(data)
        const cells = textToCellsParser(data)
        setSheetData(cells)
        setSheetUrl()
    }

    const createUrl = (uuid) => {
        return `/sheets/${uuid}`
    }

    const saveSheet = async (e) => {
        setCreating(true)
        e.preventDefault()
        const uid = auth?.currentUser?.uid
        const uuid = uuidv4()
        await sheetsCollection.add({
            uuid: uuid,
            sheetData: sheetData,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: uid ? uid : 'unknown'
        })
        setCreating(false)
        setSheetUrl(createUrl(uuid))
    }

    const clearSheet = () => {
        setSheetIdFromUrl()
        setSheetData()
        setSheetUrl()
        setPastedData()
        history.push('/')
    }

    useEffect(() => {
        if (sheetIdFromUrl && !sheetData) {
            setLoading(true)
            sheetsCollection.where("uuid", "==", sheetIdFromUrl)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const cells = doc.data().sheetData
                        setSheetData(cells)
                    })
                })
                .catch((error) => {
                    console.error("Error getting documents: ", error)
                    
                })
                .finally(() => {
                    setLoading(false)
                    setLoadingDone(true)
                })

        }
    }, [sheetIdFromUrl, sheetData, sheetsCollection])

    const LinkToSheet = () => {
        if (creating) return <CircularProgress />
        if (!sheetUrl) return ''
        return <p><a href={`${sheetUrl}`}>Link to the sheet</a></p>
    }

    const CreateSheet = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <h1>Tabularise data from spreadsheets</h1>
                    </Grid>
                </Grid>
                <form onSubmit={saveSheet}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Input"
                                helperText="Copy and paste cells from a spreadsheet"
                                variant="outlined"
                                onPaste={handlePaste}
                                value={pastedData}
                                />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button 
                                color="primary"
                                variant="contained"
                                disabled={!sheetData || creating || typeof sheetUrl !== 'undefined'}
                                onClick={saveSheet}>
                                Go
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button 
                                color="primary"
                                variant="contained"
                                disabled={creating || typeof sheetData === 'undefined'}
                                onClick={clearSheet}>
                                Clear
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <div><LinkToSheet /></div>
                        <TabularisedSheet sheet={sheetData} loading={loading} />
                    </Grid>
                </Grid>
        </>
        )
    }

    const PresentSheet = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <h1>Tabularised spreadsheet</h1>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Paper>
                            { loadingDone && typeof sheetData === 'undefined' ? 
                                <div style={{padding: '20px'}} >Sheet not found</div>
                            : <TabularisedSheet sheet={sheetData} loading={loading} />}
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <div><p>Create your own tabularised spreadsheet</p></div>
                        <Button variant="contained" color="primary" onClick={() => clearSheet()}>Create</Button>
                    </Grid>
                </Grid>
            </>
        )
    }

    if (sheetIdFromUrl) {
        return <PresentSheet />
    } else {
        return <CreateSheet />
    }
}

export default Sheets