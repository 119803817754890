import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Grid, TableContainer, Table, TableCell, TableBody, TableRow } from '@material-ui/core';
import firebase from 'firebase/app'

const PAGESIZE = 10

const Sheets = () => {
    const sheetsCollection = firebase.firestore().collection('sheets')
    const [sheets, setSheets] = useState([])
    const [lastSheet, setLastSheet] = useState()
    const [loading, setLoading] = useState(true)

    const createUrl = (uuid) => `/sheets/${uuid}`

    useEffect(() => {
        if(!loading) return
        let query
        if (lastSheet) {
            query = sheetsCollection
                .orderBy('createdAt', "desc")
                .limit(PAGESIZE)
                .startAfter(lastSheet)
        } else {
            query = sheetsCollection
                .orderBy('createdAt', "desc")
                .limit(PAGESIZE)
        }
        query
            .get()
            .then((querySnapshot) => {
                setLoading(false)
                setSheets(querySnapshot.docs.map((doc) => {
                    return { 
                        uuid: doc.data().uuid, 
                        createdAt: doc.data().createdAt.toDate().toLocaleString('sv-SE'), 
                        createdBy: doc.data().createdBy, 
                        url: createUrl(doc.data().uuid)
                    }
                }))
                setLastSheet(querySnapshot.docs[querySnapshot.docs.length-1])
            })
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sheetsCollection, loading])

    const SheetRow = ({row}) => {
        return (
            <TableRow>
                <TableCell>{row.uuid}</TableCell>
                <TableCell>{row.createdAt}</TableCell>
                <TableCell>{row.createdBy}</TableCell>
                <TableCell><a href={`${row.url}`}>Link to the sheet</a></TableCell>
            </TableRow>
        )
    }

    const SheetList = ({sheets}) => {
        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        {sheets.map((sheet) => {
                            return (<SheetRow row={{...sheet}} key={`row${sheet.uuid}`}/>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const NextButton = ({sheets, loading}) => {
        if (loading) return <CircularProgress />
        return (
            <Button 
                variant="contained"
                color="primary"
                disabled={sheets.length < PAGESIZE}
                onClick={() => {
                    setLoading(true)
                }}>
                Next
            </Button>
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs>
                    <h1>All Sheets</h1>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs>
                    <SheetList sheets={sheets} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs>
                    <NextButton sheets={sheets} loading={loading} />
                </Grid>
            </Grid>
        </>
    )
}

export default Sheets